<template>
  <BaaSDisplayBoard />
</template>

<script>
import { BaaSDisplayBoard } from '@/components/console/Index.vue';

export default {
  components: {
    BaaSDisplayBoard,
  },
};
</script>
